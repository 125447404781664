import {colors} from 'helpers/style'
import _ from 'lodash'
import moment from 'moment'
import {useEffect} from 'react'
import {useMemo} from 'react'
import {useState} from 'react'
import ApexCharts from 'react-apexcharts'

function formatTimestamp(timestamp, format = 'YYYY-MM-DD HH:mm:ss') {
  return moment.utc(timestamp).format(format)
}
function selectedTimeRange(temperatures) {
  const format = 'YYYYMMDDTHHmmss'
  const beginTime = formatTimestamp(temperatures[0].timestamp, format)
  const endTime = formatTimestamp(temperatures.slice(-1).pop().timestamp, format)
  if (beginTime.split('T')[0] == endTime.split('T')[0]) {
    return `${beginTime}-${endTime.split('T')[1]}`
  } else {
    return `${beginTime}-${endTime}`
  }
}
const TargetTempChart = ({target, temperatures, setTemperature}) => {
  const [data, setData] = useState(null)

  const handleClickOnDataPoint = index => {
    if (index >= 0) {
      setTemperature(temperatures[index])
    }
  }

  const yaxisMax = useMemo(() => {
    return target?.critical ? Math.ceil((target.critical + 5) / 10) * 10 : 120
  }, [target])

  const setup = () => {
    const ambient = _.map(temperatures, 'ext_temp')
    const maximum = _.map(temperatures, 'max')
    const minimum = _.map(temperatures, 'min')
    //const median = _.map(temperatures, 'median')
    const mean = _.map(temperatures, 'mean')
    const categories = _.map(temperatures, temp => {
      return moment(temp.timestamp).local().toISOString()
    })

    setData({
      series: [
        {
          name: 'Ambient',
          data: ambient
        },
        {
          name: 'Maximum',
          data: maximum
        },
        {
          name: 'Minimum',
          data: minimum
        },
        {
          name: 'Mean',
          data: mean
        }
      ],
      options: {
        chart: {
          type: 'area',
          events: {
            click(event, chartContext, config) {
              handleClickOnDataPoint(config.dataPointIndex)
            }
          },
          selection: {
            enabled: true
          },
          toolbar: {
            show: true,
            offsetX: 0,
            offsetY: 0,
            tools: {
              download: true,
              selection: false,
              zoom: true,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: true,
              customIcons: []
            },
            export: {
              csv: {
                filename: `${target.name} ${selectedTimeRange(temperatures)}`,
                headerCategory: 'Timestamp UTC',
                dateFormatter: formatTimestamp
              }
            }
          }
        },
        dataLabels: {
          enabled: false
        },
        colors: [colors.chart.ambient, colors.chart.maximum, colors.chart.minimum, colors.chart.median],
        stroke: {
          curve: 'smooth',
          width: 2,
          dashArray: 3
        },
        fill: {
          type: 'solid',
          opacity: 0.3,
          colors: [colors.chart.ambient, 'transparent', 'transparent', 'transparent']
        },
        xaxis: {
          type: 'datetime',
          categories: categories,
          labels: {
            datetimeUTC: false,
            datetimeFormatter: {
              year: 'yyyy',
              month: "MMM 'yy",
              day: 'MM/dd',
              hour: 'hh:mmTT'
            }
          },
          show: true
        },
        yaxis: {
          forceNiceScale: false,
          show: true,
          tickAmount: yaxisMax / 10,
          min: 0,
          max: yaxisMax
        },
        tooltip: {
          x: {
            format: 'yyyy/MM/dd HH:mm'
          }
        },
        annotations: {
          yaxis: [
            {
              y: target.warning,
              //y2: target.warning + 0.2,
              borderColor: colors.chart.warning,
              fillColor: colors.chart.warning,
              strokeDashArray: 0,
              opacity: 0.3,
              label: {
                borderColor: colors.chart.warning,
                style: {
                  color: '#fff',
                  background: colors.chart.warning
                },
                text: 'Warning',
                offsetX: 0,
                textAnchor: 'end',
                offsetY: 0
              }
            },
            {
              y: target.critical,
              //y2: target.critical + 0.2,
              borderColor: colors.chart.critical,
              fillColor: colors.chart.critical,
              strokeDashArray: 0,
              opacity: 0.3,
              label: {
                borderColor: colors.chart.critical,
                style: {
                  color: '#fff',
                  background: colors.chart.critical
                },
                text: 'Critical',
                textAnchor: 'end',
                offsetX: 0,
                offsetY: 0
              }
            }
          ]
        }
      }
    })
  }

  useEffect(() => {
    if (temperatures) setup()
  }, [temperatures])

  if (data) {
    return (
      <div id="target-temp-chart" style={{height: '500px'}}>
        <ApexCharts options={data.options} series={data.series} type="area" height={500} />
      </div>
    )
  } else {
    return <div id="target-temp-chart" style={{height: '500px'}}></div>
  }
}

export default TargetTempChart
