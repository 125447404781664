/* eslint-disable react-hooks/exhaustive-deps */
import {Card, Title} from '@tremor/react'
import {Params, useFetchGet} from 'helpers/api'
import {useEffect, useMemo, useState} from 'react'
import {Background, VictoryAxis, VictoryChart, VictoryHistogram} from 'victory'

export default function TemperaturesLastPerTarget({tenantId, type}) {
  const [targets, setTargets] = useState()
  const fetchTargets = useFetchGet('targets')

  const getTargets = () => {
    const params = Params({tid: tenantId, limit: 999})
    fetchTargets(params, response => {
      setTargets(response.targets)
    })
  }

  useEffect(() => {
    if (tenantId) getTargets()
  }, [tenantId])

  const data = useMemo(() => {
    if (!targets || targets.length === 0) return []
    return targets.map(target => {
      return {x: target[type] ? Number(target[type]) : 0}
    })
  }, [targets])

  const xaxisMax = useMemo(() => {
    if (targets && targets.length > 0) {
      const criticals = targets.map(target => Number(target.critical))
      const critical = Math.max(...criticals)
      return Math.ceil((critical + 5) / 10) * 10
    } else {
      return 120
    }
  }, [targets])

  const bins = useMemo(() => {
    return [...Array(Math.floor(xaxisMax / 5)).keys()].map(num => num * 5)
  }, [xaxisMax])

  return (
    <Card className="max-w-sm">
      <Title className="m-0 text-lg">
        {type.toUpperCase()} Temperatures ({targets?.length} assets)
      </Title>
      <VictoryChart padding={{top: 0, bottom: 60}} height={200}>
        <VictoryHistogram
          bins={bins}
          style={{data: {fill: '#ff0000'}}}
          cornerRadius={1}
          data={data}
          domain={{x: [0, xaxisMax]}}
        />
        <VictoryAxis
          label="Temperature (C)"
          // tickValues specifies both the number of ticks and where
          // they are placed on the axis
          tickCount={8}
        />
        <VictoryAxis dependentAxis crossAxis offsetX={50} />
      </VictoryChart>
    </Card>
  )
}
