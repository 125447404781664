import {
  AlertOutlined,
  AuditOutlined,
  CaretDownFilled,
  UserOutlined,
  LockOutlined,
  LogoutOutlined,
  ContactsOutlined,
  InfoCircleOutlined,
  DashboardOutlined,
  TeamOutlined,
  QuestionCircleOutlined
} from '@ant-design/icons'
import {isSysAdmin} from 'helpers/role'
import {Link} from 'react-router-dom'

export const getUserMenuItems = (user, signout) => {
  if (!user?.name) return null
  const items = {
    key: 'user',
    label: (
      <span id="user-menu">
        {user.name} <CaretDownFilled />
      </span>
    ),
    icon: <UserOutlined />,
    theme: 'light',
    children: [
      {
        key: 'profile',
        label: (
          <Link id="user-menu-profile" to="profile">
            Profile
          </Link>
        ),
        icon: <UserOutlined />
      },
      {
        key: 'changepassword',
        label: (
          <Link id="user-menu-changepassword" to="changepassword">
            Change Password
          </Link>
        ),
        icon: <LockOutlined />
      },
      {
        key: 'signoff',
        label: <span id="user-menu-signoff">Sign Off</span>,
        icon: <LogoutOutlined />,
        onClick: signout
      }
    ]
  }

  return [items]
}

export const getFeatureMenuItems = user => {
  if (!user?.name) return null
  const items = []
  items.push({
    key: 'dashboard',
    label: <Link to="dashboard">Dashboard</Link>,
    icon: <DashboardOutlined />
  })
  items.push({
    key: 'alerts',
    label: <Link to="alerts">Alerts</Link>,
    icon: <AlertOutlined />
  })

  if (user.role !== 'user') {
    items.push({
      key: 'users',
      label: <Link to="users">Users</Link>,
      icon: <UserOutlined />
    })
    items.push({
      key: 'contacts',
      label: <Link to="contacts">Contacts</Link>,
      icon: <TeamOutlined />
    })
  }

  if (isSysAdmin(user)) {
    items.push({
      key: 'audit',
      label: <Link to="audit">Audit</Link>,
      icon: <AuditOutlined />
    })
  }

  items.push({
    key: 'help',
    label: (
      <span id="feature-menu-help">
        Help <CaretDownFilled />
      </span>
    ),
    icon: <QuestionCircleOutlined />,
    children: [
      {
        key: 'contactus',
        label: (
          <Link id="feature-menu-help-contactus" to="contactus">
            Contact Us
          </Link>
        ),
        icon: <ContactsOutlined />
      }
    ]
  })

  return items
}
