/* eslint-disable react-hooks/exhaustive-deps */
import {AlertOutlined} from '@ant-design/icons'
import {Card, Title, BarList} from '@tremor/react'
import {Params, useFetchGet} from 'helpers/api'
import moment from 'moment'
import {useEffect, useMemo, useState} from 'react'

export default function Alerts24Hours({tenantId}) {
  const [alerts, setAlerts] = useState([])
  const fetchAlertlog = useFetchGet('alertlog')

  const getAlerts = start => {
    const params = Params({tid: tenantId, start, limit: 999})
    fetchAlertlog(params, response => {
      setAlerts(response.alertlog)
    })
  }

  useEffect(() => {
    if (tenantId) getAlerts(moment().local().add(-24, 'hours'))
  }, [tenantId])

  const data = useMemo(() => {
    const criticals = alerts.filter(alert => alert.desc.includes('critical')).length
    const warnings = alerts.filter(alert => alert.desc.includes('warning')).length
    return [
      {name: 'Warnings', value: warnings},
      {name: 'Criticals', value: criticals}
    ]
  }, [alerts])

  return (
    <Card className="max-w-sm">
      <Title className="m-0 text-lg">
        <AlertOutlined /> Alerts (24 hours)
      </Title>
      <BarList data={data} color={'orange'} />
    </Card>
  )
}
