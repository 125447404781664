import {CancelButton, Filter} from './styles'
import {isSysAdmin} from 'helpers/role'
import {useMemo} from 'react'
import {useSelector} from 'react-redux'

const TenantFilter = ({onFilter, filter = '', tenant, onClearTenant}) => {
  const user = useSelector(state => state.auth.user)

  const key = useMemo(() => ` ${tenant ? tenant.id : 'all'}-filter(${filter})`, [tenant, filter])

  return (
    <Filter
      data-testid="filter"
      key={key}
      addonBefore={
        isSysAdmin(user) &&
        tenant && (
          <span onClick={onClearTenant}>
            Company: <span data-testid="selected-tenant">{tenant.name}</span> <CancelButton />
          </span>
        )
      }
      placeholder="Filter..."
      defaultValue={filter}
      onSearch={onFilter}
      allowClear={!!filter}
      enterButton
    />
  )
}

export default TenantFilter
