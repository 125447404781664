/* eslint-disable react-hooks/exhaustive-deps */
import OfficeMap from './office-map'

const OfficeLocation = ({office, zoom = 15}) => {
  if (office && office.latitude && office.longitude) {
    console.log('Office', JSON.stringify(office))
    return (
      <span>
        <figure className="office-location">
          <OfficeMap office={office} zoom={zoom} />
          <figcaption>{office.latitude + ',' + office.longitude}</figcaption>
        </figure>
      </span>
    )
  } else {
    return (
      <>
        <div className="placeholder">[Add office GPS coordinates to view location on Google map]</div>
      </>
    )
  }
}

export default OfficeLocation
