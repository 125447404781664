import HomeLayout from './_layouts/home'
import MainLayout from './_layouts/main'
import ForgotPassword from './auth/forgot-password'
import Login from './auth/login'
import SetPassword from './auth/set-password'
import Home from './home'
import Alerts from './main/alerts'
import ChangePassword from './main/change-password'
import Contacts from './main/contacts'
import Audit from './main/audit'
import Dashboard from './main/dashboard'
import Target from './main/dashboard/assets/target'
import ContactUs from './main/help/contactus'
import Profile from './main/profile'
import Users from './main/users'
import {setUser} from '@store/auth'
import {beginUserSession} from '@store/index'
import {Spin} from 'antd'
import {GET} from 'helpers/api'
import {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom'

const Router = () => {
  console.log('TRACE', window.location)
  const token = localStorage.getItem('token')
  const dispatch = useDispatch()
  const isLoading = useSelector(state => token && !state.auth.user)

  useEffect(() => {
    console.log('TRACE', 'Router useEffect()', token ? 'w/token' : 'no token')
    if (token) {
      const request = GET(token)
      fetch(process.env.REACT_APP_BACKEND_URL + `/users/me`, request)
        .then(async res => {
          return res?.status === 200 ? res.json() : {}
        })
        .then(response => {
          dispatch(beginUserSession(token, response))
        })
        .catch(err => {
          dispatch(setUser({user: {}}))
        })
    } else {
      dispatch(setUser({user: {}}))
    }
  }, [dispatch, token])

  if (isLoading) {
    console.log('TRACE', 'isLoading')
    return <Spin size="large" className="root-spin" />
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="home" element={<HomeLayout />}>
          <Route index element={<Home />} />
          <Route path="*" element={<Navigate to="" />} />
        </Route>

        <Route path="auth" element={<HomeLayout />}>
          <Route index element={<Navigate to="login" />} />
          <Route path="login" element={<Login />} />
          <Route path="expired" element={<Login expired={true} />} />
          {/* <Route path="signup" element={<Signup />} /> */}
          <Route path="forgotpassword" element={<ForgotPassword />} />
          <Route path="set-password" element={<SetPassword />} />
          <Route path="*" element={<Navigate to="login" />} />
        </Route>

        {/* <Route path="admin" element={<AdminLayout />}>
          <Route index element={<Navigate to="users" />} />
          <Route path="users" element={<Users />} />
          <Route path="contacts" element={<Contacts />} />
          <Route path="*" element={<Navigate to="users" />} />
        </Route> */}

        <Route path="" element={<MainLayout />}>
          <Route index element={<Navigate to="dashboard" />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="target/:tgid" element={<Target />} />
          <Route path="alerts" element={<Alerts />} />
          <Route path="users" element={<Users />} />
          <Route path="contacts" element={<Contacts />} />
          <Route path="audit" element={<Audit />} />
          <Route path="profile" element={<Profile />} />
          <Route path="changepassword" element={<ChangePassword />} />
          <Route path="contactus" element={<ContactUs />} />
          <Route path="*" element={<Navigate to="dashboard" />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default Router
