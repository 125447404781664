import {capitalize} from 'lodash'

export const colors = {
  blue100: '#133c84',
  blue80: '#1966c2',
  blue40: '#9EB8CF',
  blue20: '#CEDCE7',
  yellow100: '#E39F19',
  yellow80: '#f5ca44',
  black: '#000000',
  white: '#ffffff',
  gray: '#DDD8D8',
  red: '#EE4B2B',
  chart: {
    warning: '#FFA500',
    critical: '#FF0000',
    normal: '#133c84',
    ambient: '#1966c2',
    maximum: '#E74C3C',
    minimum: '#AAFF00',
    median: '#133c84'
  }
}

export const pageSizeOptions = [10, 25, 50, 100, 500, 1000]
