import {GoogleMap, InfoWindow, Marker} from '@react-google-maps/api'
import {colors} from 'helpers/style'
import React, {useState, useEffect} from 'react'

const mapContainerStyle = {
  width: '100%',
  height: '100%'
}

const mapDefaultCenter = {
  lat: 38.89783477622982,
  lng: -77.03649760441895
}

const OfficeMap = ({office, zoom = 15}) => {
  const [activeInfoWindow, setActiveInfoWindow] = useState('')
  const [center, setCenter] = useState(mapDefaultCenter)
  const [markers, setMarkers] = useState([])

  useEffect(() => {
    setCenter(
      office?.latitude && office?.longitude
        ? {
            lat: office.latitude,
            lng: office.longitude
          }
        : mapDefaultCenter
    )
    setMarkers(
      office?.latitude && office?.longitude
        ? [
            {
              position: {
                lat: office.latitude,
                lng: office.longitude
              },
              label: {color: colors.white, text: office.name},
              draggable: false
            }
          ]
        : []
    )
  }, [office])

  const mapClicked = event => {
    console.log(event.latLng.lat(), event.latLng.lng())
  }

  const markerClicked = (marker, index) => {
    setActiveInfoWindow(index)
    console.log(marker, index)
  }

  const markerDragEnd = (event, index) => {
    console.log(event.latLng.lat())
    console.log(event.latLng.lng())
  }

  const onLoad = marker => {
    console.log('marker: ', marker)
  }

  return (
    <div style={{height: '300px', width: '300px'}}>
      <GoogleMap
        id={office?.id}
        mapContainerStyle={mapContainerStyle}
        center={center}
        zoom={zoom}
        onClick={mapClicked}
        options={{
          mapTypeId: 'satellite',
          controlSize: 18,
          zoomControl: true,
          mapTypeControl: true,
          scaleControl: false,
          streetViewControl: true,
          rotateControl: false,
          fullscreenControl: true
        }}
      >
        {markers.map((marker, index) => (
          <Marker
            key={index}
            position={marker.position}
            label={marker.label}
            draggable={marker.draggable}
            onLoad={onLoad}
            onDragEnd={event => markerDragEnd(event, index)}
            onClick={event => markerClicked(marker, index)}
          >
            {activeInfoWindow === index && (
              <InfoWindow position={marker.position}>
                <b>
                  {marker.position.lat}, {marker.position.lng}
                </b>
              </InfoWindow>
            )}
          </Marker>
        ))}
      </GoogleMap>
    </div>
  )
}

export default OfficeMap
