import Assets from './assets'
import Alerts24Hours from './cards/alerts24Hours'
import AssetCountCard from './cards/assetCountCard'
import TemperaturesLastPerTarget from './cards/temperaturesLastPerTarget'
import {useTenant} from '@store/settings'
import {Grid} from '@tremor/react'
import {useSelector} from 'react-redux'
import {Wrapper} from './styles'
import {Col, Row} from 'antd'

const Dashboard = () => {
  const user = useSelector(state => state.auth.user)
  const [tenant, setTenant] = useTenant(user)

  if (tenant) {
    return (
      <Wrapper>
        <Grid style={{margin: '15px'}} numItemsMd={3} className="mt-6 gap-x-6 gap-y-6">
          <AssetCountCard tenantId={tenant?.id} />
          <Alerts24Hours tenantId={tenant?.id} />
          <TemperaturesLastPerTarget tenantId={tenant?.id} type={'max'} />
        </Grid>
        <Assets tenant={tenant} handleTenantChange={setTenant} />
      </Wrapper>
    )
  } else {
    return <Assets tenant={tenant} handleTenantChange={setTenant} />
  }
}

export default Dashboard
