import {Select} from 'antd'
import {Params, useFetchGet} from 'helpers/api'
import {useEffect, useState} from 'react'

const {Option} = Select

const SiteSelect = props => {
  const [name, setName] = useState('')
  const [sites, setSites] = useState(null)
  const fetchSites = useFetchGet('sites')

  const getSites = () => {
    const params = Params({name, tid: props.tid})
    fetchSites(params, response => {
      if (props.exclude) {
        setSites(response.sites.filter(site => !props.exclude.includes(site.id)))
      } else {
        setSites(response.sites)
      }
    })
  }

  useEffect(() => {
    getSites()
  }, [name, props.tid])

  const onSearch = value => {
    setName(value)
  }

  if (props.disabled) {
    return <span className="entity">{sites?.find(site => site.id === props.value)?.name}</span>
  } else {
    return (
      <Select
        placeholder="Select a site"
        loading={!sites}
        showSearch
        onSearch={onSearch}
        {...(sites ? props : {})}
        optionFilterProp="children"
        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
      >
        {sites?.map(item => (
          <Option key={item.id} value={item.id}>
            {item.name}
          </Option>
        ))}
      </Select>
    )
  }
}

export default SiteSelect
