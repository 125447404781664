import {Select} from 'antd'
import {Params, useFetchGet} from 'helpers/api'
import {useEffect, useState} from 'react'

const {Option} = Select

const CameraSelect = props => {
  const [name, setName] = useState('')
  const [cameras, setCameras] = useState(null)
  const fetchCameras = useFetchGet('cameras')

  const getCameras = () => {
    const params = Params({name, gid: props.gid})
    fetchCameras(params, response => {
      setCameras(response.cameras)
    })
  }

  useEffect(() => {
    getCameras()
  }, [name, props.gid])

  const onSearch = value => {
    setName(value)
  }

  if (props.disabled) {
    return <span className="entity">{cameras?.find(camera => camera.id === props.value)?.name}</span>
  } else {
    return (
      <Select
        placeholder="Select a camera"
        loading={!cameras}
        showSearch
        {...(cameras ? props : {})}
        onSearch={onSearch}
        optionFilterProp="children"
        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
        mode={props.mode}
      >
        {cameras?.map(item => (
          <Option key={item.id} value={item.id}>
            {item.name}
          </Option>
        ))}
      </Select>
    )
  }
}

export default CameraSelect
