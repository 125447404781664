import Editor from './editor'
import TenantFilter from '@components/tenant-filter'
import {useAssetsSettings} from '@store/settings'
import {Button, Space, Table} from 'antd'
import PageHeader from '@components/page-header'
import {Params, useFetchGet} from 'helpers/api'
import {isSysAdmin} from 'helpers/role'
import {pageSizeOptions} from 'helpers/style'
import _ from 'lodash'
import moment from 'moment'
import {useEffect, useMemo, useState} from 'react'
import {useSelector} from 'react-redux'
import {useNavigate} from 'react-router-dom'

const getColumns = (tenant, setTenant, handleTargetClick, setSelectedItem) =>
  [
    {
      title: 'Company',
      render: (_, record) => (
        <Button
          type="link"
          onClick={e => {
            e.stopPropagation()
            setTenant(record.tenant)
          }}
        >
          {record.tenant.name}
        </Button>
      ),
      hidden: !!tenant
    },
    {
      title: 'Site',
      render: (_, record) => record.site.name
    },
    {
      title: 'Gateway',
      render: (_, record) => record.gateway.name
    },
    {
      title: 'Camera',
      render: (_, record) => record.camera.name
    },
    {
      title: 'Target',
      render: (_, record) => (
        <Button
          type="link"
          onClick={e => {
            e.stopPropagation()
            handleTargetClick(record.target)
          }}
        >
          {record.target.name}
        </Button>
      )
    },
    {
      title: 'Last Data Received',
      render: (_, record) => {
        let timestamp = null
        if (record.target && record.target.timestamp) {
          timestamp = record.target.timestamp
        } else if (record.camera && record.camera.timestamp) {
          timestamp = record.camera.timestamp
        } else if (record.gateway && record.gateway.timestamp) {
          timestamp = record.gateway.timestamp
        }

        if (timestamp) {
          // const minutesSinceLastTimestamp = moment().diff(timestamp, 'minutes')
          // const status = minutesSinceLastTimestamp <= record.site.grace_period ? 'good' : 'bad'
          return moment(timestamp).local().format('yyyy/MM/DD h:mm:ss A')
        } else {
          return <></>
        }
      }
    },
    {
      title: (
        <Button
          data-testid="add"
          className="add"
          type="link"
          onClick={() => {
            setSelectedItem({})
          }}
        >
          Add
        </Button>
      ),
      key: 'action',
      align: 'right',
      width: 200,
      render: (_, record) => {
        return (
          <Space size="middle">
            <Button
              type="link"
              onClick={e => {
                e.stopPropagation()
                setSelectedItem(record)
              }}
            >
              Edit/Add
            </Button>
          </Space>
        )
      }
    }
  ].filter(item => !item.hidden)

const Assets = ({tenant, handleTenantChange}) => {
  const navigate = useNavigate()
  const [filter, setFilter, page, setPage, limit, setLimit] = useAssetsSettings()
  const [selectedItem, setSelectedItem] = useState()
  const user = useSelector(state => state.auth.user)
  const [search, setSearch] = useState(null)
  const [searchMeta, setSearchMeta] = useState(null)
  const fetchSearch = useFetchGet('search')

  const getSearch = () => {
    const params = Params({searchTerm: filter, limit, page})
    if (isSysAdmin(user) && tenant) params.append('tid', tenant.id)
    fetchSearch(params, response => {
      setSearch(response.search)
      setSearchMeta(response.meta)
    })
  }

  useEffect(() => {
    getSearch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, page, limit, user, tenant])

  const data = useMemo(() => {
    return tenant && search && !search[0]?.site?.id ? search.slice(1) : search
  }, [search, tenant])

  const handleTargetClick = target => {
    navigate(`/target/${target?.id}`)
  }

  const columns = useMemo(() => {
    const getAllCols = getColumns(tenant, handleTenantChange, handleTargetClick, setSelectedItem)
    if (user?.role !== 'sysadmin') {
      return _.remove(getAllCols, col => {
        return user?.role === 'sysadminRO' || col.key !== 'action'
      })
    }
    return getAllCols
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.role])

  const rowKey = record => {
    return `${record?.tenant.id},${record?.site?.id},${record?.gateway?.id},${record?.camera?.id},${record?.target?.id}`
  }

  return (
    <>
      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title="Assets"
        subTitle=""
        backIcon={false}
        extra={[
          <TenantFilter
            onFilter={value => {
              setFilter(value)
              setPage(1)
            }}
            filter={filter}
            tenant={tenant}
            onClearTenant={() => handleTenantChange(null)}
          />
        ]}
      />
      <Table
        data-testid="assets-table"
        columns={columns}
        dataSource={data}
        loading={!data}
        pagination={{
          position: ['topRight', 'bottomRight'],
          total: searchMeta?.total,
          current: Number(searchMeta?.page) || 1,
          pageSize: limit,
          pageSizeOptions: pageSizeOptions,
          onChange: (_page, _limit) => {
            if (_page !== page) setPage(_page)
            if (_limit !== limit) setLimit(_limit)
            document.querySelector('main').scrollIntoView()
          }
        }}
        rowKey={record => rowKey(record)}
        rowClassName="antd-table-row"
      />
      {typeof selectedItem === 'object' && (
        <Editor item={selectedItem} onClose={() => setSelectedItem()} onSuccess={() => getSearch()} />
      )}
    </>
  )
}

export default Assets
