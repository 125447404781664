/* eslint-disable no-fallthrough */
import Address from '../../../../components/address'
import OfficeLocation from '@components/office-location'
import {Grid} from '@tremor/react'
import PageHeader from '@components/page-header'

function format(contactInfo) {
  return [
    contactInfo.name,
    contactInfo.address,
    `${contactInfo.city}, ${contactInfo.state} ${contactInfo.zipcode} ${contactInfo.country}`,
    contactInfo.email
  ]
}
const ContactUs = () => {
  const globalHQ = {
    id: 'globalhq',
    name: 'Delta Thermal, Inc.',
    address: '6339 E Speedway Blvd',
    city: 'Tucson',
    state: 'AZ',
    zipcode: '85710',
    country: 'USA',
    latitude: 32.236177938544536,
    longitude: -110.85666744232707,
    phone: '',
    email: 'support@deltathermalinc.com'
  }

  return (
    <>
      <PageHeader ghost={false} onBack={() => window.history.back()} title="Contact Us" subTitle="" backIcon={false} />
      <Grid numItemsSm={1} numItemsMd={2} numItemsLg={3} className="mt-6 gap-x-6 gap-y-6">
        <Address title="Global HQ" address={format(globalHQ)} />
        <OfficeLocation office={globalHQ} zoom={13} />
      </Grid>
    </>
  )
}

export default ContactUs
