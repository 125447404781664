import {ApartmentOutlined} from '@ant-design/icons'
import {BarList, Card, Title} from '@tremor/react'
import {Params, useFetchGet} from 'helpers/api'
import moment from 'moment'
import {useEffect, useMemo, useState} from 'react'

export default function AssetCountCard({tenantId}) {
  const [totals, setTotals] = useState([0, 0, 0])
  const [paginationOptions, setPaginationOptions] = useState({
    page: 1,
    limit: 1000
  })
  const fetchTargets = useFetchGet('targets')

  const getTargets = () => {
    const params = Params({tid: tenantId, page: paginationOptions.page, limit: paginationOptions.limit})
    fetchTargets(params, response => {
      const yesterday = moment().utc().add(-1, 'day')
      const targets = response.targets
      const total = targets.length
      const monitored = targets.filter(target => target?.timestamp !== null).length
      const active = targets.filter(target => new Date(target?.timestamp).getTime() > yesterday).length
      if (paginationOptions.page === 1) {
        setTotals([total, monitored, active])
      } else {
        setTotals([totals[0] + total, totals[1] + monitored, totals[2] + active])
      }
      if (response.meta.total > response.meta.page * response.meta.limit) {
        setPaginationOptions({page: response.meta.page + 1, limit: response.meta.pageSize})
      }
    })
  }

  useEffect(() => {
    if (tenantId) getTargets()
  }, [tenantId, paginationOptions])

  const data = useMemo(() => {
    return [
      {name: 'Total # of assets', value: totals[0]},
      {name: 'Monitored', value: totals[1]},
      {name: 'Active', value: totals[2]}
    ]
  }, [totals])

  return (
    <Card className="max-w-sm">
      <Title className="m-0 text-lg">
        <ApartmentOutlined /> Assets
      </Title>
      <BarList data={data} className="mt-2" />
    </Card>
  )
}
